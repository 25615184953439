import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@/app/shared/services/auth.service';
import { CommonService } from '@/app/shared/services/common.service';
import { environment } from '@/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {

    let token = localStorage.getItem("token");

    if (!req.url.includes("session")) {
      if (token) {
        const jwt = this.commonService.parseJwt(token);
        if (jwt.exp < Date.now() / 1000) {
          var xmlHttp = new XMLHttpRequest();
          xmlHttp.open(
            'POST',
            `${environment.baseURL}authentication/account/refresh-token`,
            false // async = false
          );
          xmlHttp.setRequestHeader('Content-Type', 'application/json');
          xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
              const jwt = JSON.parse(xmlHttp.responseText);
              localStorage.setItem("token", jwt.jwtToken);
              localStorage.setItem("refreshToken", jwt.refreshToken);
            }
          }
          xmlHttp.send(JSON.stringify({ refreshToken: localStorage.getItem("refreshToken") }));
        }
        token = localStorage.getItem("token");
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse): any => {
        if (error.status === 401 && !req.url.includes("session")) {
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            let refreshTokenValue = localStorage.getItem("refreshToken");
            if (refreshTokenValue) {
              const refreshToken = {
                refreshToken: refreshTokenValue,
              };
              return this.authService.refreshToken(JSON.stringify(refreshToken)).pipe(
                switchMap((res: any): any => {
                  if (res) {
                    this.isRefreshing = false;
                    localStorage.setItem("token", res.jwtToken);
                    localStorage.setItem("refreshToken", res.refreshToken);
                    return next.handle(req.clone({
                      setHeaders: { Authorization: `Bearer ${res.jwtToken}`, }
                    }))
                  }
                }),
                catchError((err) => {
                  this.isRefreshing = false;
                  this.authService.logout();
                  this.router.navigate(['auth/login']);
                  return throwError(() => err);
                })
              )
            } else {
              this.router.navigate(['auth/login'], {
                queryParams: {
                  returnUrl: this.router.url,
                }
              });
            }
          }
        }
        return throwError(() => error);
      })
    );
  }

}
