import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

export const routes: Routes = [
    {
        path: "",
        redirectTo: "/auth/login",
        pathMatch: "full"
    },
    {
        path: "auth",
        loadChildren: () => import("@/app/components/auth/auth.module").then(m => m.AuthModule)
    },
    {
        path: "",
        component: ContentComponent,
        children: [
            {
                path: "dashboard",
                loadChildren: () => import("@/app/components/dashboard/dashboard.module").then(m => m.DashboardModule)
            },
            {
                path: "product",
                loadChildren: () => import("@/app/components/product/product.module").then(m => m.ProductModule)
            },
            {
                path: "user",
                loadChildren: () => import("@/app/components/user/user.module").then(m => m.UserModule)
            },
            {
                path: "order",
                loadChildren: () => import("@/app/components/order/order.module").then(m => m.OrderModule)
            },
            {
                path: "role",
                loadChildren: () => import("@/app/components/role/role.module").then(m => m.RoleModule)
            },
            {
                path: "article",
                loadChildren: () => import("@/app/components/article/article.module").then(m => m.ArticleModule),
            },
            {
                path: 'sale',
                loadChildren: () => import("@/app/components/sale/sale.module").then(m => m.SaleModule),
            },
            {
                path: 'voucher',
                loadChildren: () => import("@/app/components/sale/voucher/voucher.module").then(m => m.VoucherModule),
            },
            {
                path: 'report',
                loadChildren: () => import("@/app/components/report/report.module").then(m => m.ReportModule),
            },
            {
                path: 'configuration',
                loadChildren: () => import("@/app/components/configuration/configuration.module").then(m => m.ConfigurationModule),
            },
            {
                path: 'notification',
                loadChildren: () => import("@/app/components/notification/notification.module").then(m => m.NotificationModule),
            },
            {
                path: 'job',
                loadChildren: () => import("@/app/components/job-status/job-status-routing.module").then(m => m.JobStatusRoutingModule),
            },
            {
                path: 'minigame-config',
                loadChildren: () => import("@/app/components/minigame-configuration/minigame.module").then(m => m.MinigameModule),
            },
            {
                path: 'notification',
                loadChildren: () => import("@/app/components/notification/notification.module").then(m => m.NotificationModule),
            },
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: "enabledBlocking",
            scrollPositionRestoration: 'enabled',
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
